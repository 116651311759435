import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from "gatsby"
import { createGlobalStyle } from "styled-components"
import styled from "styled-components"
import { mq, elevation, offset, offsetXxl, gutter } from "../utils/presets"
import avatar from '../assets/images/Evan_Photo.jpg'
import Top from "../components/top-button"
import SEO from "../components/seo"
import getEducation from "../components/cv/education"
import getProfessional from "../components/cv/professional"
import getResearch from "../components/cv/research"
import getSkills from "../components/cv/skills"
import getField from "../components/cv/field"
import getPresentations from "../components/cv/presentations"
import getTeaching from "../components/cv/teaching"
import getPublications from "../components/cv/publications"
import CVPage from "./cv"

const GlobalStyle = createGlobalStyle`
  body {
    height: 100%;
    background-color: #f6f5ed;
  }
`

const Main = styled.header`
`

const Header = styled.header`
    margin: 3rem auto;
    width: 60%;
    ${mq.tablet} {
        overflow: hidden;
        width: 70%;
    }
    ${mq.mobile} {
        overflow: hidden;
        width: 90%;
        margin-bottom: 0;
        margin-left: 1rem;
        margin-top: 1rem;
        margin-right: 1rem;
    }
`;

const Article = styled.article`
    font-family: Georgia, Palatino, 'Palatino Linotype', Times, 'Times New Roman', serif;
    color: #484848;
    font-size: 18px;
    line-height: 26px;
    margin: 3rem auto;
    width: 60%;
    ${mq.tablet} {
        width: 70%;
        margin-top: 0;
    }
    ${mq.mobile} {
        width: 90%;
        overflow: hidden;
        margin-bottom: 0;
        margin-left: 1rem;
        margin-top: 1rem;
        margin-right: 1rem;
    }
`;

const Name = styled.h1`
    font-family: Georgia, Palatino, 'Palatino Linotype', Times, 'Times New Roman', serif;
    justify-content: left;
    font-size: 38px;
    line-height: 42px;
    font-weight: normal;
    margin-bottom: 40px;
`;

const Headshot_img = styled.img`
    width: 200px;
    height: 200px;
    border-radius: 150px;
    float: left;
    margin-left: 0;
    margin-right: 20px;
    margin-top: 0;
    margin-bottom: 2px;
    ${mq.mobile} {
        width: 130px;
        height: 130px;
        line-height: 30px;
        justify-content: center;
        margin-left: 0;
        margin-right: 15px;
        margin-top: 5px;
        margin-bottom: 0;
    }
`;

const Bio = styled.p`
    font-family: Georgia, Palatino, 'Palatino Linotype', Times, 'Times New Roman', serif;
    color: #484848;
    font-size: 18px;
    line-height: 26px;
    ${mq.mobile} {
        word-wrap: break-word;
    }
`

const links = styled.p`
    font-family: Georgia, Palatino, 'Palatino Linotype', Times, 'Times New Roman', serif;
    color: #484848;
    font-size: 18px;
    line-height: 26px;
    margin-left: 15px;
`

const Heading = styled.h2`
    margin-top: 80px;
    margin-bottom: 20px;
    font-size: 25px;
    font-weight: normal;
    ${mq.mobile} {
        margin-top: 0
    }
`

const Link = styled.a`
    color: #484848;
    text-decoration: none;
    border-bottom-width: 1px;
    border-bottom-color: #999892;
    border-bottom-style: dotted;

    &:hover {
        border-bottom: 1px solid #999892
    }
`

const Red_Link = styled.a`
    color: #C30C0C;
    text-decoration: none;
    border-bottom-width: 1px;
    border-bottom-color: #999892;
    border-bottom-style: dotted;

    &:hover {
        border-bottom: 1px solid #999892
    }
`

const HomeIndex = ({ siteTitle, siteDescription, data }) => (
    <Main>
        <GlobalStyle />
        <Helmet>
                <title>{siteTitle}</title>
                <meta name="description" content={siteDescription} />
        </Helmet>
        
        <Header>
            <Name id="top">Evan Greenberg</Name>
            <Headshot_img src={avatar} alt="" />
            <Bio>I am currently a PhD Candidate in 
                <Link href="https://geomorph.geog.ucsb.edu/"> Geography at UC Santa Barbara</Link>. 
                My dissertation focuses on terrestiral geomorphic processes using multi-spectral time series. I'm broadly interested in remote sensing methods and have worked with both multi-spectral and hyper-spectral methods on data handling and correction as well as scientific applications.

            </Bio>
            <Bio> 
                You can find links to my &nbsp;
                <Red_Link href="https://github.com/evan-greenbrg">Github</Red_Link>, &nbsp;
                <Red_Link href="https://scholar.google.com/citations?user=DVWLuFMAAAAJ&hl=en">Google Scholar</Red_Link>&nbsp; 
                and I welcome emails to&nbsp;
                <Red_Link href="mailto:ev.ben.green@gmail.com">ev.ben.green@gmail.com</Red_Link>.
            </Bio>
        </Header>
        <Article>
            <div className="major">
                <h2>Curriculum Vitae</h2>
            </div>
            <Top />
            <section>
                <h4>Education:</h4>
                {getEducation(data.educationData)}
            </section>
            <section>
                <h4><br />Publications:</h4>
                {getPublications(data.publicationsData)}
            </section>
            <section>
                <h4>Research Experience:</h4>
                {getResearch(data.researchData)}
            </section>
            <section>
                <h4>Professional Experience:</h4>
                {getProfessional(data.professionalData)}
            </section>
            <section>
                <h4><br />Presentations:</h4>
                {getPresentations(data.presentationsData)}
            </section>
            <section>
                <h4>Teaching Experience:</h4>
                {getTeaching(data.teachingData)}
            </section>
            <section>
                <h4>Field Work:</h4>
                {getField(data.fieldData)}
            </section>
            <section>
                <h4>Technology I've Used:</h4>
                {getSkills(data.skillsData)}
            </section>
        </Article>
    <SEO title="CV" keywords={[`gatsby`, `greenberg`, `react`, 'geology', 'graduate', 'geography']} />
    </Main>
)

export default HomeIndex

export const query = graphql`
  query {
	educationData: allEducationJson {
		edges {
			node {
				School
				City
				State
				Degree
				Department
				GPA
                Thesis
			}
		}
	},
	professionalData: allProfessionalJson {
		edges {
			node {
				Title
				Department
				Company
				City
				State
				StartDate
				EndDate
				Descriptions
			}
		}
	},
	researchData: allResearchJson {
		edges {
			node {
				Title
				Department
				Company
				City
				State
				StartDate
				EndDate
				Descriptions
			}
		}
	},
	thesesData: allThesesJson {
		edges {
			node {
				Title
				Description
				Link
			}
		}
	},
	skillsData: allSkillsJson {
		edges {
			node {
                skills
			}
		}
	},
	fieldData: allFieldJson {
		edges {
			node {
                date
                datetext
                location
                description
			}
		}
	},
	presentationsData: allPresentationsJson {
		edges {
			node {
                author1
                author2
                year
                title
                where
			}
		}
	},
	teachingData: allTeachingJson {
		edges {
			node {
                Title
                Department
                Company
                City
                State
                StartDate
                EndDate
			}
		}
	},
	publicationsData: allPublicationsJson {
		edges {
			node {
                author1
                author2
                year
                title
                where
                stage
                doi
			}
		}
	}
  }
`
